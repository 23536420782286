import React, { useState, useEffect } from 'react';
import HeroImage from '../assets/heroBaner.jpg';
import Spinner from './Spinner';
import { CSSTransition } from 'react-transition-group';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-scroll';
import AboutImg from '../assets/about2.json';
function Hero() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Simulating image loading time
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="bg-gray-100 p-10 md:p-20 flex flex-col md:flex-row items-center bg-decorative">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <CSSTransition
            in={!loading}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div className="md:w-1/2 text-center md:text-left transition-all duration-500 transform-gpu">
              <h2 className="text-3xl md:text-4xl font-bold mb-4">L’ARTISANAT <span className='text-sky-500 border-b-4 border-yellow-400'>LOGICIEL</span>  AU SERVICE DE VOS <span className='text-yellow-400 border-b-4 border-sky-500'>PROJETS</span></h2>
              {/* <p className="text-lg mb-8">D'une simple idée à une solution opérationnelle, nous concevons des logiciels fiables et évolutifs adaptés à vos besoins.</p> */}
              <p className="text-lg mb-8">Que vous ayez besoin d'une application web, mobile ou de bureau, nous mettons en œuvre les dernières technologies pour vous offrir des solutions innovantes et performantes. Nous vous accompagnons également dans votre transformation numérique, que ce soit pour la modernisation de vos systèmes legacy ou le développement de nouvelles fonctionnalités</p>
              {/* <button className="bg-black-400 inline-block  rounded-lg px-5 py-3 font-medium text-white sm:w-autorounded transform hover:scale-105 transition-transform duration-300">Contactez nous</button> */}
              <Link to="contact" smooth={true} duration={500} className="hover:text-white-500 transition 
              duration-300 cursor-pointer
             w-56 rounded-lg text-white
               px-5 py-3  text-center
               inline-block
                font-semibold bg-blue-600 ">contactez nous</Link>


              {/* <button
                  type="submit"
                  class="inline-block w-full rounded-lg bg-yellow-400 px-5 py-3 font-medium text-white sm:w-auto"
                >
                  Envoyer
                </button> */}
            </div>
          </CSSTransition>
          <CSSTransition
            in={!loading}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <div className="md:w-1/2 mt-10 md:mt-0 transition-all duration-500 transform-gpu">
           {/* //   <img src={HeroImage} alt="Illustration" className="rounded shadow-md transform hover:scale-105 transition-transform duration-300" /> */}
              <Player
              autoplay
               loop
              src={AboutImg}
              style={{ height: '500px', width: '600px' }}
            >
             
            </Player>
            </div>
          </CSSTransition>
        </>
      )}
    </section>
  );
}

export default Hero;

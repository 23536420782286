import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import api from '../api';


function ContactForm() {
  const notifySuccess = () => toast.success('Vos informations ont été enregistrées avec succès!');
  const notifyError = () => toast.error('"Oops, une erreur est survenue. Veuillez réessayer !"');
  const [form, setForm] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    body: "",
  });
  const [error, setError] = useState()

  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!form.email || !form.firstName || !form.lastName || !form.phoneNumber || !form.body) {
      setError('Veuillez remplir tous les champs.');

    }
    const _form = {
      firstname: form.firstName,
      lastname: form.lastName,
      email: form.email,
      phone_no: form.phoneNumber,
      body: form.body,
    };

    api.post(`/customers/add`, _form).then((res) => {

      if (res.data.status === "OK") {
        document.getElementById("resetForm").reset();
        setForm({
          email: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
          body: "",
        })

        notifySuccess();

      }
    }).catch(error => notifyError());
  };


  return (
    <section id='contact' class="bg-sky-100">
      <div class="container mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div class="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">

          <div class="lg:col-span-2 lg:py-12">
            <h1 className='text-6xl mb-5'>Parlez-nous de votre projet !</h1>
            {/* <h2 className="max-w-xl text-3xl font-bold text-gray-500">Nous contacter</h2> */}
            <div class="mt-2">
              <p className="text-2xl font-bold text-gray-700">contact@synapsepixel.com</p>
              <p className="text-2xl font-bold text-yellow-500 mt-2"> (+225) 07 0840 6613 </p>
              <address className="mt-2 not-italic text-2xl font-bold text-gray-700">Abidjan cocody 2 Plateaux</address>
            </div>
          </div>

          <div class="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
            <Toaster />
            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <form action="#" id="resetForm" class="space-y-4" onSubmit={handleSubmit}>
              <div>
                <label class="sr-only" for="prenom">Prénom</label>
                <input
                  class="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow"
                  placeholder="Prénom"
                  type="text"
                  id="prenom"
                  value={form.firstName}
                  onChange={(e) =>
                    handleChange("firstName", e.target.value)
                  }
                /> </div>
              <div>
                <label class="sr-only" for="prenom">Nom</label>
                <input
                  class="w-full rounded-lg border-gray-200 p-4 pe-12 text-sm shadow"
                  placeholder="Nom"
                  type="text"
                  id="nom"
                  value={form.lastName}
                  onChange={(e) =>
                    handleChange("lastName", e.target.value)
                  }
                /> </div>
              <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label class="sr-only" for="email">Adresse email</label>
                  <input
                    class="w-full rounded-lg border-gray-200 p-3 text-sm shadow"
                    placeholder="Adresse email"
                    type="email"
                    id="email"
                    value={form.email}
                    onChange={(e) =>
                      handleChange("email", e.target.value)
                    }
                  />
                </div>
                <div>
                  <label class="sr-only" for="phone">Telephone</label>
                  <input
                    class="w-full rounded-lg border-gray-200 p-3 text-sm shadow"
                    placeholder="Numéro de téléphone avec indcatif du pays"
                    type="tel"
                    id="phone"
                    value={form.phoneNumber}
                    onChange={(e) =>
                      handleChange("phoneNumber", e.target.value)
                    }
                  />
                </div>
              </div>
              <div>
                <label class="sr-only" for="message">Palez nous de votre projet</label>
                <textarea
                  class="w-full rounded-lg border-gray-200 p-3 text-sm shadow"
                  placeholder="Palez nous de votre projet"
                  rows="8"
                  id="message"
                  value={form.body}
                  onChange={(e) =>
                    handleChange("body", e.target.value)
                  }
                ></textarea>
              </div>
              <div class="mt-4">
                <button
                  type="submit"
                  class="inline-block w-full rounded-lg bg-yellow-400 px-5 py-3 font-medium text-white sm:w-auto"
                >Envoyer
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </section>
  );
}

export default ContactForm;

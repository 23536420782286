import React from 'react';
// import AboutImg from '../assets/Humaaans .png';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import AboutImg from '../assets/Heros.json';
function AboutUs() {
  return (
    <section id="about-us">
      <div class="mx-auto container px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 bg-white rounded-xl mb-5">
        <div class="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div class="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
            {/* <img

              src={AboutImg}
              class="absolute inset-0 h-full w-full object-cover"
            /> */}
            <Player
              autoplay
              loop
              src={AboutImg}
            // style={{ height: '300px', width: '300px' }}
            >

            </Player>
          </div>
          <div class="lg:py-20">
            <h2 class="text-3xl font-bold sm:text-4xl">VOTRE ARTISANAT LOGICIEL</h2>

            <p class="mt-4 text-gray-600 mb-5">
              <span className='uppercase'>SYNAPSE πxel</span> est une agence spécialisée dans le développement de solutions logicielles sur mesure.
            </p>
            <p class="mt-4 text-gray-600 mb-5">
              Notre objectif ? Vous accompagner dans vos projets stratégiques en concevant des logiciels
              personnalisés qui favorisent la croissance de votre organisation et répondent parfaitement à vos besoins spécifiques.</p>
            <p className='mt-4 text-gray-600'>Notre mission est de devenir le partenaire technologique de choix pour nos clients, en leur fournissant des solutions logicielles innovantes et personnalisées qui stimulent leur croissance et leur succès à long terme.</p>

            <p class="mt-4 text-gray-600 mb-5">Nous offrons des tarifs et forfaits flexibles adaptés aux besoins spécifiques de chaque client.</p>

          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;

import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Projects from './components/Projects';
import ContactForm from './components/ContactForm';
import CTA from './components/CTA';
import Newsletter from './components/Newsletter';
import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div>
      <Header />
      <Hero />
     
      <Services />
      <AboutUs />
      {/* <Projects /> */}
      <ContactForm />
      {/* <CTA /> */}
      {/* <Newsletter /> */}
      <Footer />
     
    </div>
  );
}

export default App;

import React from 'react';
import ScrollToTop from './ScrollToTop';
import { Link } from 'react-scroll';
function Footer() {
  return (
    <footer class="bg-blue-600">
      <div class="relative mx-auto container  px-3 py-3 sm:px-3 lg:px-3 lg:pt-10">
        <ScrollToTop />
        <div class="lg:flex lg:items-end lg:justify-between">
          <div>
            <h1 className='text-white font-extrabold'>SYNAPSE PiXel</h1>

            <p class="mx-auto mt-6 max-w-md text-center leading-relaxed text-white lg:text-left">
           <span className='font-bold'>L’ARTISANAT LOGICIEL AU SERVICE DE VOS PROJETS</span> <br/>
            D'une simple idée à une solution opérationnelle, nous concevons des logiciels fiables et évolutifs adaptés à vos besoins.
            </p>
          </div>
        </div>

        <p class="mt-12 text-center text-sm text-gray-50 lg:text-right">
          Copyright &copy; 2024. All rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;

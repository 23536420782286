import React from 'react';

function Newsletter() {
  return (
    <section className="p-10 md:p-20 bg-gray-100 text-center rounded border border-gray-300 bg-decorative">
      <h2 className="text-3xl font-bold mb-4">Restez Informé</h2>
      <p className="text-lg mb-8">Inscrivez-vous à notre newsletter pour recevoir les dernières nouvelles et offres.</p>
      <form className="flex flex-col md:flex-row justify-center items-center">
        <input type="email" placeholder="Votre email" className="p-2 rounded border border-gray-300 mb-4 md:mb-0 md:mr-4" />
        <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded">S'inscrire</button>
      </form>
    </section>
  );
}

export default Newsletter;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { CSSTransition } from 'react-transition-group';
import Spinner from './Spinner';
import LogoSynapsepixel from '../assets/logoSynapsepixel.png';
function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setLoading(true);
    setTimeout(() => {
      setIsOpen(!isOpen);
      setLoading(false);
    }, 500);
  };

  return (
    <header className={`fixed w-full z-50 p-4 ${scrolled ? 'header-scrolled' : ''}
   bg-gradient-to-r from-primary to-secondary text-light p-4 sticky top-0 z-50 shadow-lg 
    `}>
      <div className="container mx-auto flex justify-between items-center flex-wrap">
        {/* <h1 className="text-3xl font-bold bg-gradient-to-r from-blue-600 via-blue-400 to-yellow-500 inline-block text-transparent bg-clip-text uppercase">SYNAPSE πxel</h1> */}
        <img src={LogoSynapsepixel} alt="Illustration" className="w-auto max-h-14 shadow-0" />

        <nav className="hidden md:flex space-x-4">
          <Link to="about-us" smooth={true} duration={500} className="hover:text-yellow-500 transition duration-300 cursor-pointer uppercase font-semibold">Qui sommes nous</Link>
          <Link to="services" smooth={true} duration={500} className="hover:text-yellow-500 transition duration-300 cursor-pointer  uppercase font-semibold">Nos Services</Link>
          {/* <Link to="projects" smooth={true} duration={500} className="hover:text-yellow-500 transition duration-300 cursor-pointer  uppercase font-semibold">Nos Réalisations</Link> */}
          <Link to="contact" smooth={true} duration={500} className="hover:text-yellow-500 transition duration-300 cursor-pointer  uppercase font-semibold">Contact</Link>
        </nav>
        <button className="md:hidden" onClick={toggleMenu}>
          <svg className="w-6 h-6 transition-transform duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
          </svg>
        </button>
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        classNames="menu"
        unmountOnExit
      >
        <div className="md:hidden bg-blue-600 text-white space-y-2 p-4 absolute w-full left-0 top-16">
          <Link to="about-us" smooth={true} duration={500} className="block hover:text-yellow-500 transition duration-300 cursor-pointer">Qui sommes nous</Link>
          <Link to="services" smooth={true} duration={500} className="block hover:text-yellow-500 transition duration-300 cursor-pointer">Nos Services</Link>
          <Link to="projects" smooth={true} duration={500} className="block hover:text-yellow-500 transition duration-300 cursor-pointer">Nos Réalisations</Link>
          <Link to="contact" smooth={true} duration={500} className="block hover:text-yellow-500 transition duration-300 cursor-pointer">Contact</Link>
        </div>
      </CSSTransition>
      {loading && <Spinner />}
    </header>
  );
}

export default Header;

import React from 'react';
import MobileIcon from '../assets/mobile_dev.svg';
import WebIcon from '../assets/web_dev.svg';
import ChatbotIcon from '../assets/uix.svg';
import MarketingIcon from '../assets/devops.svg';
import { CSSTransition } from 'react-transition-group';
function Services() {
  return (
    <section id="services" className="p-10 md:p-20 bg-white">
      <h2 className="text-3xl font-bold text-center mb-10">Nos Services</h2>
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* <CSSTransition
          // key={index}
          in={true}
          appear={true}
          timeout={300}
          classNames="fade"
        > */}
        <div className="bg-gray-100 p-6 rounded shadow text-centerx">
          {/* <img src={MobileIcon} alt="Développement Mobile" className="w-16 h-16 mx-auto mb-4" /> */}

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
           className="size-24 w-24 h-24 mx-auto mb-4 text-blue-600">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672Zm-7.518-.267A8.25 8.25 0 1 1 20.25 10.5M8.288 14.212A5.25 5.25 0 1 1 17.25 10.5" />
</svg>

          
          <h3 className="text-2xl font-bold mb-4">Développement Mobile</h3>
          <p className='font-semibold '>Applications mobiles performantes pour iOS et Android.</p>
          <p>
          <span className='uppercase'>SYNAPSE π-xel</span> est spécialisé dans la création d'applications mobiles natives, de haute qualité,
            conçues pour offrir une expérience utilisateur exceptionnelle sur iOS et Android.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded shadow text-centerx">
          {/* <img src={WebIcon} alt="Développement Web" className="w-16 h-16 mx-auto mb-4" /> */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" 
          class="size-24 w-24 h-24 mx-auto mb-4 text-yellow-400">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 6.75 22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3-4.5 16.5" />
</svg>

          <h3 className="text-2xl font-bold mb-4">Développement Web</h3>
          <p className='font-semibold'>Création de sites web modernes et responsives.</p>
          <p>
            <span className='uppercase'>SYNAPSE π-xel</span> conçoit des sites et applications web sur mesure,
            robustes et sécurisées, qui s'intègrent parfaitement à votre écosystème numérique.
            Nous accompagnons votre entreprise à chaque étape de son développement,
            en vous offrant des solutions digitales innovantes et adaptées à vos besoins spécifiques.
          </p>
        </div>
        <div className="bg-gray-100 p-6 rounded shadow text-centerX">
          {/* <img src={MarketingIcon} alt="Consultance Marketing Digital" className="w-16 h-16 mx-auto mb-4" /> */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           class="size-24 w-24 h-24 mx-auto mb-4 text-blue-500">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
</svg>


          <h3 className="text-2xl font-bold mb-4">Consultance Marketing Digital</h3>
          <p className='font-bold'>Stratégies de marketing digital pour booster votre visibilité.</p>
          <p>Nous  vous proposons une approche personnalisée pour optimiser votre présence en ligne.
            en  commencant  par analyser votre situation actuelle et vos objectifs pour élaborer une stratégie sur-mesure.
            Nos  services incluent</p>
          <ul>
            <li>Augmenter votre visibilité sur le web.</li>
            <li>Générer plus de leads et de conversions.</li>
            <li>Améliorer votre image de marque</li>
            <li>Développer votre chiffre d'affaires.</li>
          </ul>
        </div>
        {/* </CSSTransition> */}
      </div>
    </section>
  );
}

export default Services;
